var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card-title',[_vm._v("Product gallery")])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"disabled":_vm.$cannot('product.create'),"variant":"primary"},on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","disabled":_vm.$cannot('product.create')},on:{"change":function($event){return _vm.pick($event)}}}),_vm._v(" Pick Image ")]),_c('b-modal',{attrs:{"id":"addImage","title":"Add Image","size":"lg","hide-footer":""}},[_c('div',{staticClass:"nav-wizards-container"},[_c('b-nav',{staticClass:"nav-wizards-2 mb-2"},_vm._l((_vm.sizes),function(item,key,index){return _c('b-nav-item',{key:key,staticClass:"col",attrs:{"link-classes":_vm.stepClass(index + 1)}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_vm._l((_vm.sizes),function({ width, height },key,index){return _c('div',{key:key},[(_vm.currentStep === index + 1)?_c('b-card',[_c('cropper',{ref:`cropper-${index}`,refInFor:true,attrs:{"src":_vm.thumbnail,"stencil-props":{
                handlers: {},
                movable: false,
                scalable: false,
              },"stencil-size":{
                width: width,
                height: height,
              },"image-restriction":"stencil"},on:{"change":args => _vm.cropperChange(key, args)}})],1):_vm._e()],1)}),_c('b-row',{staticClass:"mt-4",attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"auto"}},[(!_vm.firstStep)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"link"},on:{"click":_vm.previousStep}},[_vm._v(" Back ")]):_vm._e(),(!_vm.lastStep)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Next ")]):_vm._e(),(_vm.lastStep)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.$cannot('product.create') || _vm.isLoading},on:{"click":_vm.upload}},[_vm._v(" Crop and Upload "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"ml-2",attrs:{"small":"","label":"Loading"}})],1):_vm._e()],1)],1)],2)],1)],1),_c('hr'),_c('draggable',{staticClass:"row",on:{"end":_vm.setNewOrder},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"3"}},[_c('b-button-close',{on:{"click":function($event){return _vm.deleteItem(item)}}}),_c('b-img',{attrs:{"src":item.small_url,"fluid":""}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }