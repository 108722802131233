var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card-title',[_vm._v(_vm._s(_vm.cardText))]),(!_vm.shouldCrop)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"disabled":_vm.$cannot('product.create'),"variant":"primary"},on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","disabled":_vm.$cannot('product.create')},on:{"change":function($event){return _vm.pick($event)}}}),_vm._v(" Pick Image ")])],1),(_vm.thumbnails.length > 0)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-row',_vm._l((_vm.thumbnails),function(thumbnail){return _c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-xl-left mb-1"},[_vm._v(_vm._s(thumbnail.name))]),_c('b-img',{attrs:{"src":thumbnail.url,"width":"268"}})],1)}),1)],1):_vm._e()],1):_vm._e(),(_vm.shouldCrop)?[_c('div',{staticClass:"nav-wizards-container"},[_c('b-nav',{staticClass:"nav-wizards-2 mb-2"},_vm._l((_vm.sizes),function(item,key,index){return _c('b-nav-item',{key:key,staticClass:"col",attrs:{"link-classes":_vm.stepClass(index + 1)}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_vm._l((_vm.sizes),function({ width, height },key,index){return _c('div',{key:key},[(_vm.currentStep === index + 1)?_c('b-card',[_c('cropper',{ref:`cropper-${index}`,refInFor:true,attrs:{"src":_vm.thumbnail,"stencil-props":{
            handlers: {},
            movable: false,
            scalable: false,
          },"stencil-size":{
            width: width,
            height: height,
          },"image-restriction":"stencil"},on:{"change":args => _vm.cropperChange(key, args)}})],1):_vm._e()],1)}),_c('hr'),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"auto"}},[(_vm.firstStep)?_c('b-button',{staticClass:"mr-2",on:{"click":_vm.cancelStep}},[_vm._v(" Cancel ")]):_vm._e(),(!_vm.firstStep)?_c('b-button',{staticClass:"mr-2",on:{"click":_vm.previousStep}},[_vm._v(" Back ")]):_vm._e(),(!_vm.lastStep)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Next ")]):_vm._e(),(_vm.lastStep)?_c('b-button',{attrs:{"disabled":_vm.$cannot('product.create') || _vm.isLoading,"variant":"primary"},on:{"click":_vm.upload}},[_vm._v(" Crop and Upload "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"ml-2",attrs:{"small":"","label":"Loading"}})],1):_vm._e()],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }