<template>
  <div>
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Product variations.</h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button
          variant="default"
          :to="{ name: 'product.variations.new' }"
          :disabled="$cannot('product-variation.create')"
        >
          New Variation
        </b-button>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(id)="{ item }">
          <router-link :to="{ name: 'product.variations.edit', params: { variation: item.id } }">{{
            item.id
          }}</router-link>
        </template>
        <template v-slot:cell(variation_name)="{ item }">
          <router-link :to="{ name: 'product.variations.edit', params: { variation: item.id } }">{{
            item.variation_name
          }}</router-link>
        </template>
        <template v-slot:cell(supplier)="{ item }">
          <router-link v-if="item.booking_type === 'date'" :to="{name: 'suppliers.bookings', params: {id: item.supplier.id}}" target="_blank">
            {{item.supplier.booking_name}}
          </router-link>
          <div v-else>
            {{item.supplier.booking_name}}
          </div>
        </template>
        <template v-slot:cell(status)="{ item }">
          <b-badge :variant="statusVariant(item.status)">{{ statusText(item.status) }}</b-badge>
        </template>

        <template v-slot:cell(options)="row">
          <b-button-group>
            <b-button
              variant="default"
              size="sm"
              :to="{ name: 'product.variations.edit', params: { variation: row.item.id } }"
              :disabled="$cannot('product-variation.update')"
            >
              Edit
            </b-button>
          </b-button-group>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { products } from '@/api'

export default {
  name: 'ProductsVariation',
  inject: ['$cannot'],
  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'variation_name' },
        { key: 'place', formatter: place => place.name },
        { key: 'supplier' },
        {
          key: 'price',
          formatter(price) {
            const formatter = new Intl.NumberFormat('nb-NO', {
              style: 'currency',
              currency: price.current.currency,
            })

            return formatter.format(price.current.amount / 100)
          },
        },
        {
          key: 'purchased_count',
          formatter(count) {
            return new Intl.NumberFormat('en-US').format(count)
          },
        },
        { key: 'status' },
        { key: 'options', label: '' },
      ]
    },
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const id = this.$route.params.id
      const response = await products.variations.go(id, page)

      this.$nextTick(() => {
        this.listing = response.data()
        this.meta = response.meta
      })
    },
    paginationChange(page) {
      this.$router.push(
        page === 1 ? { name: 'products.edit.variations' } : { name: 'products.edit.variations', query: { page } }
      )

      this.loadContent()
    },
    handleClose(close) {
      this.loadContent()
      close()
    },
    statusVariant(status) {
      return ['danger', 'primary', 'info'][status]
    },
    statusText(status) {
      return ['Inactive', 'Active', 'Bookable'][status]
    },
  },
}
</script>
