<template>
  <b-card no-body>
    <b-tabs nav-class="p-3" pills card vertical>
      <b-tab title="Thumbnail" v-bind="tabProperties">
        <product-images-thumbnail :product="product" @updated="$emit('updated')" />
      </b-tab>

      <b-tab title="Gallery" v-bind="tabProperties" lazy>
        <product-images-gallery :product="product" @updated="$emit('updated')" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ProductImagesThumbnail from './ProductImagesThumbnail.vue'
import ProductImagesGallery from './ProductImagesGallery.vue'

export default {
  name: 'ProductImages',
  components: { ProductImagesThumbnail, ProductImagesGallery },
  props: { product: Object },
  computed: {
    tabProperties() {
      return {
        class: 'border-left p-4',
        style: 'min-height:300px',
      }
    },
  },
}
</script>
