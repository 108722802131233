<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Reply" v-slot="context" rules="required">
        <b-form-group label="Reply">
          <b-form-textarea
            v-model="reply"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g Excellent feedback Bjorn."
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="link" class="mr-2" @click="$emit('close')">
            Close
          </b-button>

          <b-button variant="primary" :disabled="invalid" type="submit">
            Reply
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { products } from '@/api'
import { getValidationState } from '../page-helpers'

export default {
  name: 'ProductReviewReplyForm',
  props: { review: { type: Object } },
  data() {
    return { reply: '' }
  },
  methods: {
    getValidationState,
    async submitFormData() {
      await products.reviews.reply(this.review.id, this.reply)
      this.$bvToast.toast(`You've added a new reply to the review by "${this.review.name}"`, {
        title: 'Review replied',
        variant: 'primary',
      })

      this.$emit('close')
    },
  },
}
</script>
