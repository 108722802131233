<template>
  <div>
    <b-row align-v="center">
      <b-col>
        <h2>Reviews</h2>

        <b-form-group>
          <b-form-radio-group v-model="selected" :options="options" @change="tabGoTo" />
        </b-form-group>
      </b-col>

      <b-col cols="auto">
        <b-button variant="primary" v-b-modal.newReview>Add Review</b-button>

        <b-modal id="newReview" title="New Review" hide-footer v-slot="{ close }">
          <product-review-form @close="refresh(close)" />
        </b-modal>
      </b-col>
    </b-row>

    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(rating)="row">
          <b-form-rating v-model="row.item.rating" readonly inline no-border />
        </template>

        <template v-slot:cell(options)="{ item }">
          <b-dropdown :disabled="item.trashed" text="More" variant="light" size="sm" right>
            <b-dropdown-item v-if="!item.approved" @click="approveReview(item)">
              Approve
            </b-dropdown-item>
            <b-dropdown-item v-b-modal="`editReview-${item.id}`">
              Edit
            </b-dropdown-item>

            <b-dropdown-item v-if="item.approved" v-b-modal="`replyReview-${item.id}`">
              Reply
            </b-dropdown-item>

            <b-dropdown-item v-b-modal="`deleteReview-${item.id}`">
              Delete
            </b-dropdown-item>
          </b-dropdown>

          <b-modal :id="`editReview-${item.id}`" title="Edit Review" hide-footer v-slot="{ close }">
            <product-review-form :review="item" @close="refresh(close)" />
          </b-modal>

          <b-modal :id="`replyReview-${item.id}`" title="Reply Review" hide-footer v-slot="{ close }">
            <product-review-reply-form :review="item" @close="refresh(close)" />
          </b-modal>

          <b-modal :id="`deleteReview-${item.id}`" title="Delete Review" @ok="deleteReview(item)">
            Are you sure you want to delete review by
            <strong>{{ item.name }}?</strong>
            <pre class="mt-4">{{ item.review }}</pre>
          </b-modal>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-if="meta.total"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { products } from '@/api'
import ProductReviewForm from './ProductReviewForm.vue'
import ProductReviewReplyForm from './ProductReviewReplyForm.vue'

export default {
  name: 'ProductReviews',
  components: { ProductReviewForm, ProductReviewReplyForm },
  props: { product: Object },
  data() {
    return {
      selected: this.$route.query.tab ?? 'pending',
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'name' },
        { key: 'email' },
        { key: 'rating' },
        { key: 'review' },
        { key: 'approved' },
        { key: 'vote_count' },
        { key: 'options', label: '' },
      ]
    },
    options() {
      return [
        { text: 'Pending', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Deleted', value: 'deleted' },
        { text: 'All', value: 'all' },
      ]
    },
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const tab = this.$route.query.tab
      const approved = ['pending', 'approved'].includes(tab) ? { approved: tab === 'approved' } : {}
      const trashed = tab === 'deleted' ? { trashed: 'only' } : {}
      const all = tab === 'all' ? { trashed: 'with' } : {}
      const filter = {
        product_id: this.$route.params.id,
        ...approved,
        ...trashed,
        ...all,
      }

      const reviews = await products.reviews.all(null, { filter, page })

      this.listing = reviews.data()
      this.meta = reviews.meta
    },

    tabGoTo(tab) {
      this.$router.push({
        name: 'products.edit.reviews',
        params: { id: this.$route.params.id },
        query: { tab },
      })

      this.loadContent()
    },

    paginationChange(page) {
      this.$router.push(
        page === 1
          ? { name: 'products.edit.reviews' }
          : { name: 'products.edit.reviews', query: { page } }
      )
      this.loadContent()
    },

    refresh(close) {
      this.loadContent()
      close()
    },

    async approveReview(item) {
      await products.reviews.approve(item.id)
      this.loadContent()
      this.$bvToast.toast(`Review by "${item.name}" was approved.`, {
        title: 'Review Approved',
        variant: 'primary',
      })
    },

    async deleteReview(item) {
      await products.reviews.delete(item.id)
      this.loadContent()
      this.$bvToast.toast(`Review by "${item.name}" was deleted.`, {
        title: 'Review deleted',
        variant: 'primary',
      })
    },
  },
}
</script>
