<template>
  <b-list-group-item class="d-flex align-items-center">
    <div class="flex-fill">
      <div>{{ title }}</div>
      <p class="small muted mb-1" v-if="description" v-html="description"></p>
      <div class="text-gray-700 d-flex align-items-center">
        <svg
          :class="indicatorClasses"
          aria-hidden="true"
          focusable="false"
          data-prefix="fa"
          data-icon="circle"
          role="img"
          viewBox="0 0 512 512"
        >
          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
        </svg>

        <span>{{ descriptionText }}</span>
      </div>
    </div>
    <div class="width-100">
      <slot name="action" :variant="buttonVariant" :text="buttonText" :disabled="buttonDisabled">
        <b-button :variant="buttonVariant" class="width-100" @click="change" :disabled="buttonDisabled">
          {{ buttonText }}
        </b-button>
      </slot>
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'SettingToggle',
  props: { title: String, status: Boolean, buttonDisabled: Boolean, description: String },
  computed: {
    descriptionText() {
      return this.status ? 'Enabled' : 'Disabled'
    },
    buttonVariant() {
      return this.status ? 'default' : 'primary'
    },
    buttonText() {
      return this.status ? 'Disable' : 'Enable'
    },
    indicatorClasses() {
      return ['svg-inline--fa fa-circle fa-w-16 fs-8px fa-fw mr-1', this.status ? 'text-success' : 'text-muted']
    },
  },
  methods: {
    change() {
      this.$emit('change', !this.status)
      console.log(this.status)
    },
  },
}
</script>
