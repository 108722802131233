<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <multi-select
        v-model="variation"
        placeholder="Pick a product variation"
        track-by="variation_place_name"
        label="variation_place_name"
        :options="variationOptions"
      >
        <template slot="option" slot-scope="props">
            {{ props.option.variation_place_name }}
        </template>
      </multi-select>

      <template>
        <validation-provider name="Name" v-slot="context" rules="required">
          <b-form-group label="Name">
            <b-form-input
              v-model="name"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
              placeholder="e.g Bjørn Andreas"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Email" v-slot="context" rules="required|email">
          <b-form-group label="Email">
            <b-form-input
              v-model="email"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
              placeholder="e.g bjorn@youwish.no"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Age" v-slot="context">
          <b-form-group label="Age">
            <b-form-input
              v-model="age"
              type="number"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Gender" v-slot="context" rules="required">
          <b-form-group label="Gender">
            <b-form-radio-group
              v-model="gender"
              :options="options"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
              button-variant="primary"
              buttons
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Rating" v-slot="context" rules="required">
          <b-form-group label="Rating">
            <b-form-rating v-model="rating" :state="getValidationState(context)" v-bind="context.ariaInput" />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Review" v-slot="context" rules="required">
          <b-form-group label="Review">
            <b-form-textarea
              v-model="content"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
              placeholder="e.g Great product"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row align-h="end">
          <b-col cols="auto">
            <b-button variant="link" class="mr-2" @click="$emit('close')">
              Close
            </b-button>

            <b-button variant="primary" :disabled="invalid" type="submit">
              {{ isEditPage ? 'Update' : 'Save' }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { products } from '@/api'
import { getValidationState } from '../page-helpers'

export default {
  name: 'ProductReviewForm',
  components: { MultiSelect },
  props: { review: { type: Object } },
  data() {
    return {
      variation: null,
      variationOptions: [],
      name: '',
      email: '',
      content: '',
      age: 0,
      gender: 'male',
      rating: 3,
    }
  },
  computed: {
    isEditPage() {
      return !!this.review
    },
    options() {
      return [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Other', value: 'other' },
      ]
    },
  },
  beforeMount() {
    this.preloadContent()
  },
  methods: {
    getValidationState,
    async preloadContent() {
      this.variationOptions = (await products.variations.all(this.$route.params.id ?? this.review.product_id)).data()

      if (!this.isEditPage) return
      if (this.review.product_variation_id) {
        const variation = await products.variations.find(this.review.product_variation_id)
        this.variation = variation.data
      }

      this.name = this.review.name
      this.email = this.review.email
      this.content = this.review.review
      this.age = this.review.age
      this.gender = this.review.gender
      this.rating = this.review.rating
    },
    async submitFormData() {
      const data = {
        name: this.name,
        email: this.email,
        review: this.content,
        age: this.age,
        gender: this.gender,
        rating: this.rating,
        product_variation_id: this.variation ? this.variation.id : null,
      }

      if (!this.isEditPage) {
        await products.reviews.create(this.variation.product_id, data)
        this.$bvToast.toast(`Review by "${this.name}" created.`, {
          title: 'Review created',
          variant: 'primary',
        })
      } else {
        await products.reviews.update(this.review.id, data)
        this.$bvToast.toast(`Review by "${this.name}" updated.`, {
          title: 'Review updated',
          variant: 'primary',
        })
      }

      this.$emit('refresh')
      this.$emit('close')
    },
  },
}
</script>
